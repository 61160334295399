import { useEffect, useState } from 'react';
import { PageSectionKeys } from '../constants';

export default function useScrollableNavigation() {
	const [activeMenuItem, setActiveMenuItem] = useState(PageSectionKeys.home);

	const scrollToSection = (section: string) => {
		if (section === PageSectionKeys.home) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			return;
		}

		const element = document.getElementById(section);
		if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const sections = document.querySelectorAll('[data-landing-section]');

			if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
				setActiveMenuItem(PageSectionKeys.contacts);
				return;
			}

			sections.forEach((section) => {
				const sectionTop = section.getBoundingClientRect().top + scrollPosition - 100;
				const sectionBottom = sectionTop + section.clientHeight - 100;

				if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
					setActiveMenuItem(section.id);
				}
			});
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return {
		activeMenuItem,
		scrollToSection,
	};
}
