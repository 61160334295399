export const PageSectionKeys = {
	home: 'home',
	features: 'features',
	about: 'about',
	contacts: 'contacts',
};

export const PageSections = [
	{
		displayName: 'Gravity GRC',
		key: PageSectionKeys.home,
	},
	{
		displayName: 'Key Features',
		key: PageSectionKeys.features,
	},
	{
		displayName: 'About Us',
		key: PageSectionKeys.about,
	},
	{
		displayName: 'Contacts',
		key: PageSectionKeys.contacts,
	},
];
