import React, { FC } from 'react';
import styles from './LandingPage.module.scss';
import Header from '../components/partials/header/Header';
import FeaturesSection from '../components/partials/features-section/FeaturesSection';
import AboutSection from '../components/partials/about-section/AboutSection';
import ContactsSection from '../components/partials/contacts-section/ContactsSection';
import TopSection from '../components/partials/top-section/TopSection';
import useScrollableNavigation from '../utils/hooks/useScrollableNavigation';
import { PageSectionKeys } from '../utils/constants';

const LandingPage: FC = () => {
	const { activeMenuItem, scrollToSection } = useScrollableNavigation();

	return (
		<div className={styles.landing}>
			<Header activeMenuItem={activeMenuItem} scrollToSection={scrollToSection} />

			<main className={styles.main}>
				<section id={PageSectionKeys.home} data-landing-section>
					<TopSection />
				</section>

				<section id={PageSectionKeys.features} data-landing-section>
					<FeaturesSection scrollToSection={scrollToSection} />
				</section>

				<section id={PageSectionKeys.about} data-landing-section>
					<AboutSection />
				</section>

				<section id={PageSectionKeys.contacts} data-landing-section>
					<ContactsSection
						activeMenuItem={activeMenuItem}
						scrollToSection={scrollToSection}
					/>
				</section>
			</main>
		</div>
	);
};

export default LandingPage;
