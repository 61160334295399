import React, { FC, useState } from 'react';
import styles from './FeaturesList.module.scss';
import FeatureListItem from '../../../primitives/feature-list-item/FeatureListItem';
import Button from '../../../primitives/button/Button';
import { PageSectionKeys } from '../../../../utils/constants';

const FeaturesList: FC<{ scrollToSection: (key: string) => void }> = ({ scrollToSection }) => {
	const [activeFeature, setActiveFeature] = useState<string>('1');

	return (
		<section className={styles['key-features']}>
			<div className={styles.info}>
				<h2>Key Features</h2>

				<p>
					GravityGRC is engineered to empower organizations with a comprehensive,
					intuitive, and scalable platform for managing governance, risk, and compliance.
					Here's an exploration of its standout features:
				</p>
			</div>

			<div className={styles['feature-items']}>
				<FeatureListItem
					featureKey="1"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Centralized Communication Hub'}
					description={
						'Facilitate cross-departmental collaboration with a centralized communication hub. This feature allows team members to discuss issues and provide updates within the context of specific compliance tasks or risk assessments, enhancing efficiency and accountability.'
					}
				/>

				<FeatureListItem
					featureKey="2"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Real-time Visibility'}
					description={
						'GravityGRC’s dashboards provide real-time insights into governance, risk, and compliance metrics. Customizable views allow stakeholders to monitor key indicators, track progress towards compliance goals, and identify emerging risks at a glance.'
					}
				/>

				<FeatureListItem
					featureKey="3"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Compliance Mapping'}
					description={
						'Intelligent Compliance Mapping: Leveraging artificial intelligence, GravityGRC identifies applicable regulations and maps them against your organization’s policies and controls. This AI-driven approach ensures comprehensive coverage of all compliance requirements, minimizing gaps and redundancies.'
					}
				/>

				<FeatureListItem
					featureKey="4"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Risk Analytics'}
					description={
						'Predictive Risk Analytics: GravityGRC utilizes predictive analytics to forecast potential risks based on industry trends, historical data, and current organizational metrics. This proactive risk identification helps organizations prioritize their mitigation efforts and allocate resources more effectively. Advanced Reporting and Dashboards'
					}
				/>

				<FeatureListItem
					featureKey="5"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Task Automation and Assignment'}
					description={
						'Streamline GRC processes with automated task creation and assignment. GravityGRC ensures that tasks related to compliance activities, risk mitigation strategies, and policy reviews are automatically assigned to the appropriate team members.'
					}
				/>

				<FeatureListItem
					featureKey="6"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'In-depth Analytics and Reporting'}
					description={
						'Offering advanced analytics capabilities, GravityGRC generates detailed reports on compliance status, risk exposure, and policy effectiveness. These insights support strategic decision-making, enabling organizations to refine their GRC strategies based on data-driven evidence. Collaborative Workflow Management'
					}
				/>

				<FeatureListItem
					isPreLast
					featureKey="7"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Dynamic Policy Management'}
					description={
						'Automated Policy Updates: GravityGRC’s dynamic policy management system automates the creation, updating, and dissemination of policy documents in real-time. When regulatory changes occur or new risks are identified, the system automatically updates relevant policies, ensuring that all documentation is current without manual intervention.'
					}
				/>

				<FeatureListItem
					isLast
					featureKey="8"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Policy Template Library'}
					description={
						'Customizable Policy Templates: With an extensive library of customizable policy templates, organizations can quickly draft policies that align with specific regulatory requirements and industry standards. This streamlines the policy creation process, saving time and ensuring compliance. AI-Driven Compliance and Risk Assessment'
					}
				/>
			</div>

			<Button
				width={174}
				className={styles.action}
				onClick={() => scrollToSection(PageSectionKeys.contacts)}
			>
				Get GRC Access
			</Button>
		</section>
	);
};

export default FeaturesList;
